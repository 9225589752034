@import "styles/helpers";

.card {
  width: 100%;
  @include element-flex(column, flex-start);
  gap: 12px; }

.card_head {
  margin-bottom: 0; }

.skeleton {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  border-radius: 10px;
  @include t {
    flex-direction: column; }
  span {
    @include dark {
      --base-color: #313131;
      --highlight-color: #525252; } } }

.item {
  width: 100%;
  height: 25px; }
