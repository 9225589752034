@import "styles/helpers";

.container {
  width: 100%;
  height: 300px;
  max-height: 800px; }

.map {
  width: 100%;
  height: 100%;
  border-radius: 16px; }
