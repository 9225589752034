@import "styles/helpers";

.container {
    @include element-flex(column, space-between);
    gap: 14px;
    width: 100%; }

.label {
    @include base2; }

.date_picker {
    z-index: 11;
    width: 100%;
    color: $n7;
    display: flex;
    border: 2px solid gray;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; } }

.calendar_icon {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px; }

.calendar {
    padding: 12px;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border-radius: 12px;
    border: 2px solid $n2;
    box-shadow: 0px 2px 7px $shades3;
    @include dark {
        background-color: black;
        border-color: $n6;
        box-shadow: 0px 2px 7px $shades4; } }

.header {
    display: grid;
    grid-template-columns: auto 25px 25px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: black;
    margin-bottom: 4px;
    @include dark {
        color: white; } }

.header_button {
    @include title2;
    color: black;
    @include dark {
        color: white; } }

.data_container {
    @include element-flex(row);
    padding-left: 8px; }

.select_head {
    width: 100%;
    line-height: 24px;
    height: 24px;
    padding: 0 8px;
    box-shadow: none;
    @include dark {
        box-shadow: none; }
    &:before {
        background: none;
        width: 0; }
    &:hover {
        box-shadow: none;
        @include dark {
            box-shadow: none; } } }

.header_select {
    width: fit-content;
    overflow-y: scroll;
    max-height: 250px;
    text-align: left; }

 :global {
    .react-datepicker__header {
        padding: 8px 0 0 !important; } }
