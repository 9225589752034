@import "../../../styles/helpers";

.card {
    margin-bottom: 12px;
    margin-top: 12px; }

.separator {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #DCDCDC;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-block;
    @include dark {
        border-bottom: 1px solid #272b30; } }

.separator_text {
    text-align: center;
    @include body1-m;
    color: #6f767e; }

.technology {
    display: flex;
    justify-content: center; }

.technology_name {
    width: fit-content;
    margin-bottom: 24px;
    padding: 8px;
    @include body1-s;
    border-radius: 12px;
    border: solid 2px rgb(235,235,235);
    color: black;
    @include dark {
        border: solid 2px #6f767e;
        color: white; } }

.earnings {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px 16px; }

.earnings_monthly {
    min-width: 250px;
    display: inline-block;
    text-align: center;
    width: 30%; }

.earnings_font_PLN {
    margin-bottom: 10px;
    @include body1-s;
    color: black;
    @include dark {
        color: white; } }

.earnings_font_info {
    @include caption1-m;
    color: #6f767e; }

.earnings_font_median {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: black;
    @include dark {
        color: white; } }
