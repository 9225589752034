@import "styles/helpers";

.container {
    @include element-flex(row, space-between, center);
    gap: 8px;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: black;
    background: $n2;
    flex-wrap: wrap;
    @include dark {
        color: white;
        background: $n6; } }

.placement {
    @include element-flex(row, flex-start,center);
    gap: 8px;
    @include body1-m;
    &:hover {
        color: $p1; } }

.activity {
    @include element-flex(row, flex-start);
    gap: 4px;
    margin-left: auto;
    @include caption1-m; }

.activity_text {
    color: $n4; }


