@import "styles/helpers";

.card {
  min-height: 180px;
  @include m {
    min-height: auto; } }

.list {
  @include element-flex(column);
  gap: 12px; }

.loader {
  margin: 0 auto 36px; }
