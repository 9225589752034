@import "styles/helpers";

.container {
    width: 100%;
    @include element-flex(row,flex-start);
    gap: 12px;
    @include t {
        flex-direction: column;
        justify-content: center;
        align-items: center; } }

.container_info {
    width: 100%;
    @include element-flex(column,flex-start);
    @include body1-m;
    span {
        color: $n4; } }

.container_buttons {
    @include element-flex(column,flex-end);
    @include body1-m;
    gap: 8px;
    @include t {
        flex-direction: row; } }

.title {}

.slug {}

.post_id {}

.author {}

.author_id {}

.views {}

.comments {}

.likes {}

.dislikes {}

.show_button {
    width: 150px;
    @include t {
        width: 100px; }
    button {
        line-height: 1;
        width: 100%; } }

.edit_button {
    width: 150px;
    @include t {
        width: 100px; }
    button {
        line-height: 1;
        width: 100%; } }

.delete_button {
    width: 150px;
    @include t {
        width: 100px; }
    button {
        line-height: 1;
        width: 100%;
        background: none;
        border: solid 2px red;
        color: black;
        @include dark {
            color: white; }
        &:hover {
            background: red;
            color: white; } } }

.modal_container {
    max-width: 600px; }

.delete_container {
    @include element-flex(column);
    gap: 24px;
    &:nth-child(1) {
        justify-content: center;
        align-items: center; } }

.delete_info {
    width: calc(100% - 75px); }

.confirm_delete {
    button {
        background: none !important;
        color: black;
        @include dark {
            color: white; }
        border: solid 2px red;
        &:hover {
            background: red !important;
            color: white; } } }
