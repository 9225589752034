@import "styles/helpers";

.item {
  @include element-flex(column);
  gap: 12px;
  padding: 12px 16px;
  background-color: $n2;
  border-radius: 10px;
  @include dark {
    background-color: $n6; }

  .description {
    color: $n3;
    @include body1-m; }

  &.active {}


  .breadcrumb {
    padding: 0; } }

.info {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  @include info; }

.description {
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    &.showMore {
      display: block;
      -webkit-line-clamp: unset; } }

.showMore {
  color: $p1;
  cursor: pointer;
  @include body1-m; }
