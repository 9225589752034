@import "styles/helpers";

.container {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    grid-gap: 8px;
    padding: 20px 0;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; }
    @include t {
        @include element-flex(column);
        gap: 8px;
        position: relative; } }

.title_container {
    @include element-flex(row);
    gap: 4px;
    @include t {
        width: 90%; } }

.title {
    display: flex;
    align-items: center;
    @include body1-m;
    color: black;
    @include dark {
        color: white; }
    &:hover {
        color: $p1; } }

.author_container {
    @include element-flex(row);
    gap: 4px;
    @include t {
        width: 90%; } }

.author {
    display: flex;
    align-items: center;
    @include body1-m;
    color: black;
    @include dark {
        color: white; }
    &:hover {
        color: $p1; } }

.views {
    width:  90%;
    @include element-flex(row, center, center);
    gap: 4px;
    @include body1-m;
    @include t {
        width:  90%;
        justify-content: flex-start;
        svg {
            display: none; } } }

.reactions {
    @include body1-m;
    @include element-flex(row, center, center);
    gap: 8px;
    @include t {
        width:  90%;
        justify-content: flex-start; } }

.reactions_comments {
    @include element-flex(row, flex-start, center);
    gap: 4px; }

.reactions_likes {
    @include element-flex(row, flex-start, center);
    gap: 4px; }

.reactions_dislikes {
    @include element-flex(row, flex-start, center);
    gap: 4px; }

.mobile_text {
    display: none;
    min-width: 88px;
    @include t {
        display: flex; }
    @include body1-m;
    color: $n4; }

.options {
    @include element-flex(row,flex-end, center);
    gap: 4px;
    @include t {
        @include element-flex(column,flex-end, center);
        position: absolute;
        right: 0; } }

.detail_button {
    padding: 4px;
    svg {
        fill: $n4; }
    &:hover {
        cursor: pointer;
        border-radius: 10px;
        background-color: $n3;
        @include dark {
            background-color: $n6; } } }

.edit_button {
    padding: 4px;
    svg {
        fill: $n4; }
    &:hover {
        cursor: pointer;
        border-radius: 10px;
        background-color: $n3;
        @include dark {
            background-color: $n6; } } }

.trash_button {
    padding: 4px;
    svg {
        fill: $n4; }
    &:hover {
        background-color: $n3;
        @include dark {
            background-color: $n6; }
        cursor: pointer;
        border-radius: 10px;
        svg {
            fill: red; } } }


.modal_container {
    max-width: 600px; }

.delete_container {
    @include element-flex(column);
    gap: 24px;
    &:nth-child(1) {
        justify-content: center;
        align-items: center; } }

.delete_info {
    width: calc(100% - 75px); }

.confirm_delete {
    button {
        background: none !important;
        color: black;
        @include dark {
            color: white; }
        border: solid 2px red;
        &:hover {
            background: red !important;
            color: white; } } }

