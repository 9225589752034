@import "styles/helpers";

.skeleton {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  border-radius: 10px;
  @include t {
    flex-direction: column; }
  span {
    @include dark {
      --base-color: #313131;
      --highlight-color: #525252; } } }

.left {
  width: 50%;
  height: 49px;
  @include t {
    width: 100%; } }

.right {
  width: 50%;
  height: 49px;
  @include t {
    width: 100%; } }
