@import "styles/helpers";

.card {
    width: 100%;
    @include element-flex(column, flex-start);
    gap: 12px; }

.card_head {
    margin-bottom: 0; }

.item {
    @include element-flex(row, flex-start, center);
    gap: 8px;
    @include title2; }

.item_tip {
    @include body1-m;
    color: $n4; }
