@import "styles/helpers";

.row {
  display: flex;
  //min-width: 900px
  @include t {
    display: block; } }

.col {
  width: 100%; }

.nav {
  @include element-flex(row, flex-start);
  gap: 12px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    flex-shrink: 0;
    padding: 6px 12px;
    border-radius: 8px;
    color: $n4 !important;
    &.active {
      background: $n3;
      color: $n8 !important;
      svg path {}
      fill: $n8;
      @include dark {
        background: $n7;
        color: $n !important;
        svg path {}
        fill: $n; } }
    &:hover {
      color: $n8 !important;
      svg path {}
      fill: $n8;
      @include dark {
        color: $n !important;
        svg path {}
        fill: $n; } }
    svg path {
        fill: $n4; }
    @include dark {
      color: $n1;
      svg path {}
      fill: $n4; } } }

.mobile_nav {
  margin-bottom: 32px; }
