@import "styles/helpers";

.header_container {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
    grid-gap: 8px;
    padding: 20px 0;
    height: 69px;
    @include t {
        display: none; } }

.header_title {
    display: flex;
    align-items: center;
    @include caption1-m;
    color: $n4; }

.header_author {
    display: flex;
    align-items: center;
    @include caption1-m;
    color: $n4; }

.header_views {
    display: flex;
    align-items: center;
    justify-content: center;
    @include caption1-m;
    color: $n4; }

.header_reactions {
    display: flex;
    align-items: center;
    justify-content: center;
    @include caption1-m;
    color: $n4; }

.container {
    width: 100%;
    @include element-flex(column, flex-start, space-between);
    //gap: 12px
    border-top: 1px solid $n4; }
