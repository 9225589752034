@import "styles/helpers";

.placement {
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: $n3;
    @include dark {
        background-color: $n5; } }

.text {
    color: black;
    @include dark {
        color: white; }
    text-align: center; }
