@import "styles/helpers";

.container {
    @include element-flex(column,space-between);
    gap: 24px; }

.header {
    @include title1-s;
    font-weight: 500; }

.skills_list {
    @include element-flex(row);
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; }

.skill_container {
    @include element-flex(row);
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background: $n3;
    @include dark {
        background: $n6; }
    @media (max-width: 600px) {
        width: 100%; } }

.image {
    height: 72px;
    width: 72px;
    border-radius: 12px;
    img {
        border-radius: 12px;
        height: 100%;
        width: 100%;
        object-fit: contain; } }

.details {
    word-break: break-all; }

.skill_name {
    @include body1-m; }

.skill_level {
    @include body1-m;
    color: $n4; }

.skill_progress {
    @include element-flex(row);
    height: 24px;
    align-items: center;
    gap: 4px; }

.skill_dot {
    height: 12px;
    width: 12px;
    background-color: $p1;
    border-radius: 50%;
    display: inline-block; }

.skill_dot_empty {
    height: 12px;
    width: 12px;
    background-color: none;
    border: 2px solid $p1;
    border-radius: 50%;
    display: inline-block; }

.no_information {
    @include body1-m;
    color: $n4; }
