@import "styles/helpers";

.placement {
    @include element-flex(row, flex-start,center);
    gap: 8px;
    @include body1-m; }

.activity {
    @include element-flex(row, flex-start,center);
    gap: 4px;
    @include caption1-m;
    margin-left: auto; }

.activity_text {
    color: $n4; }

.container {
    @include element-flex(row, space-between,center);
    gap: 8px;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    color: black;
    flex-wrap: wrap;
    //background: $n2
    @include dark {
        //color: white
 } }        //background: $n6
