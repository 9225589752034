@import "styles/helpers";

.card {
    width: 100%; }

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-gap: 8px;
    align-items: center;
    @include t {
        grid-template-columns: auto; } }


.date_container {
    width: 100%;
    @include element-flex(row, flex-start,center);
    gap: 8px;
    width: 100%;
    align-items: center; }

.date_picker {
    min-width: 100px;
    max-width: 250px; }

.text {
    @include title1-s; }
