@import "styles/helpers";

.container {
    @include element-flex(column,flex-start);
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    @include hide-scrollbar;
    @include custom-scrollbar; }

.item {
    @include element-flex(row,flex-start);
    &:not(:first-child) {
        border-top: 1px solid $n3; }
    @include dark {
        border-color: $n5; }
    padding: 2px;
    gap: 4px;
    @include body1-m;
    span {
        color: $n4; } }

.text {}
