@import "styles/helpers";

.switch {
  @include element-flex(row, flex-start);
  gap: 8px; }

.data {
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  @include t {
    max-height: 1000px; } }
