@import "../../styles/helpers";

$color-error-msg: #cf0000;

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 12px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;
    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }

.row {
    display: flex;
    padding-bottom: 40px;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 296px);
            width: calc(100% - 296px); }
        @include t {
            width: 100%;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include d {
            width: 296px; }
        @include t {
            width: auto; } } }


.fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px; }


.postcreated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    padding: 5rem;
    p {
        font-size: 1.6em;
        margin-bottom: 2em; }
    .panel {
        button {
            margin-right: 2em; } } }

.modal_outer_container {
    max-width: 500px; }

.modal_container {
    @include element-flex(column, space-between);
    gap: 12px; }

.container {
    width: 100%;
    @include element-flex(row, space-between);
    gap: 12px; }

.modal_button {
    min-width: 100px;
    max-width: 150px;
    width: 100%; }
