@import "styles/helpers";

.earnings {
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  @include t {
    max-height: 1000px; } }
