@import "styles/helpers";

$color-error: #cf0000;

.input_container {
  width: 100%;
  position: relative;
  @include element-flex(row, space-between);
  align-items: center;
  gap: 12px; }

.input {
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.hint {
  // margin-top: 4px
  // padding: 10px 15px
  // width: fit-content
  // cursor: pointer
  // border-radius: $default-radius
  // //border-bottom: 2px solid $p1
 }  // color: $tag-text-color

.container {
  margin-top: 24px;
  // display: flex
  // align-items: center
  // flex-wrap: wrap
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 36px;
  border-radius: 2px;
  width: 100%; }

.tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  //background-color: $n3
  //color: $n7
  color: $tag-text-color; }

.item {
  width: 100%;
  //max-width: 120px
  display: flex;
  flex-direction: column;
  gap: 12px; }

.tech_stack_item {
  max-width: 100%; }

.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 100%;
  border: 1px solid $n3;
  border-radius: 10px;
  cursor: pointer;
  transition: .2s ease-in-out;
  @include dark {
    border-color: $n5; }
  svg {
    path {
      fill: $n4;
      transition: .2s ease-in-out; } }
  &:hover {
    border-color: $color-error;
    background-color: $color-error;
    svg {
      path {
        fill: $n1; } } } }

.level {
  color: $n4; }

.tech_stack {
  width: 100%;
  margin-top: 12px; }

 :global {
  .mobile {
     :local {
      .input_container {
        grid-template-columns: 1fr 100px; }
      .input {
        grid-column-start: 1;
        grid-column-end: 3; } } } }

 :global {
  .tiny {
     :local {
      .input_container {
        grid-template-columns: 1fr; } } } }
