@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px; }

.stats {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr)); }

.card {
  padding: 24;
  @include element-flex(column, flex-start);
  gap: 24px; }

.card_head {
  margin-bottom: 0; }

.section_text {
  width: 100%;
  @include element-flex(column, flex-start);
  gap: 8px; }

.activity_text {
  @include info; }
